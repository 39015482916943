import React, { useState } from "react";
import "./Navbar.css"; // Import the CSS file
import yearbook23 from "../images/yearbook23.png";
import yearbook23blue from "../images/yearbook23blue.png";
import styled from "styled-components";

const VoteButton = styled.button`
  // border: 2px solid black;
  border: none;
  font-size: 1.2rem;
  background-color: white;
  color: black;
  // border-radius: 26px;
  padding: 12px 20px 6px;
  text-transform: uppercase;
  margin: 12px auto 8px;
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <nav key={isMenuOpen} className="navbar">
        {/* Left Links */}
        <div className="navbar-left">
          <a className="nav-link" href="/2021">
            2021
          </a>
          <a className="nav-link" href="/2022">
            2022
          </a>
          <a className="nav-link" href="/2023">
            2023
          </a>
        </div>

        {/* Center Logo */}
        <div className="navbar-center">
          <a href="/">
            <img id="logo" className="logo" src={yearbook23} alt="Logo" />
            <img
              id="logo-blue"
              className="logo"
              src={yearbook23blue}
              alt="Logo"
            />
          </a>
        </div>

        {/* Right Link */}
        <div className="navbar-right">
          <a id="vote-desktop" href="/vote">
            <VoteButton>VOTE FOR THE 2023 MVP</VoteButton>
          </a>
        </div>
      </nav>
      {/* <div>
        <a id="mobile-menu" href="/vote">
          <VoteButton>VOTE FOR THE 2023 MVP</VoteButton>
        </a>
      </div> */}
    </div>
  );
};

export default Navbar;
