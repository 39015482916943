import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Artists2023 } from "./data";
import axios from "axios";
import useConfetti from "./ConfettiWrapper";
import tie from "./images/tie.gif";
import "animate.css";
import nosignal from "./images/nosignal.svg";
import { Link } from "react-router-dom";

function validEmail(e) {
  var filter =
    /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
  return String(e).search(filter) != -1;
}

const Vote = ({ email: _email, name: _name }) => {
  const [fireConfetti] = useConfetti();
  const [artists, setArtists] = React.useState([]);
  const [selectedArtist, setSelectedArtist] = React.useState({});
  const [voted, setVoted] = React.useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState(_email);
  const [name, setName] = useState(_name);
  const [votedStatus, setVotedStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const [requireEmail, setRequireEmail] = useState(false);

  useEffect(() => {
    const randArtists = Artists2023.sort(() => 0.5 - Math.random());
    setArtists(randArtists);
    setSelectedArtist(randArtists[0]);
  }, []);

  useEffect(() => {
    if (!name || !email) setRequireEmail(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const vote = async () => {
    setLoading(true);
    const response = await axios.post("/api/vote", {
      artist: selectedArtist.title,
    });
    const { artistStatus } = response.data;
    if (artistStatus) {
      setVotedStatus(`${artistStatus.votes} votes and counting`);
    }
    setLoading(false);
    setVoted(true);
  };

  const VoteButton = styled.button`
    border: none;
    // font-family: "nichrome-black";
    font-size: 1.2rem;
    background-color: white;
    color: black;
    // border-radius: 26px;
    padding: 12px 25px 8px;
    text-transform: uppercase;
    height: 47px !important;
  `;

  const ShareLink = styled.a`
    border: 2px solid black;
    // font-family: "nichrome-black";
    font-size: 1.2rem;
    background-color: black;
    color: white;
    // border-radius: 26px;
    padding: 12px 32px 8px;
    text-transform: uppercase;
    margin: 24px auto;
  `;

  const Select = styled.select`
    width: 200px;
    // font-family: 'nichrome-regular';
    text-align: center;
    padding: 8px;
    border-width: 2px;
    font-size: 1.2rem;
    border-color: black;
}`;

  const inputStyle = {
    // fontFamily: "nichrome-regular",
    fontSize: "1.2rem",
    height: 30,
    width: 200,
    borderWidth: 2,
    borderColor: "black",
    textAlign: "center",
    border: "none",
  };

  // body background color
  // logo inverse to blue
  // fonts to blue
  // nav bar blue links

  useEffect(() => {
    if (voted) {
      const originalBodyBackgroundColor =
        document.getElementsByTagName("body")[0].style.backgroundColor;
      const originalNavbarClass =
        document.getElementsByTagName("nav")[0].classList.value;
      const originalLogoBlueDisplay =
        document.getElementById("logo-blue").style.display;
      const originalLogoDisplay = document.getElementById("logo").style.display;

      document.getElementsByTagName("body")[0].style.backgroundColor =
        "#f8f1ec";
      const navbar = document.getElementsByTagName("nav")[0];
      navbar.classList.add("navbar-blue");
      document.getElementById("logo-blue").style.display = "block";
      document.getElementById("logo").style.display = "none";

      // Return a cleanup function to reset the overrides when the component unmounts
      return () => {
        try {
          document.getElementsByTagName("body")[0].style.backgroundColor =
            originalBodyBackgroundColor;
          navbar.classList.value = originalNavbarClass;
          document.getElementById("logo-blue").style.display =
            originalLogoBlueDisplay;
          document.getElementById("logo").style.display = originalLogoDisplay;
        } catch (e) {
          console.log(e);
        }
      };
    }
  }, [voted]);

  if (voted)
    return (
      <div>
        <div
          style={{
            padding: 40,
            minHeight: "100vh",
            maxWidth: 1080,
            margin: "auto",
            textAlign: "center",
            // backgroundColor: "#f8f1ec",
          }}
        >
          <div style={{ width: "fit-content", margin: "auto" }}>
            {/* <div style={{ textAlign: "left", marginBottom: 20 }}>
              <a style={{ fontSize: 18 }} href="/2023">
                {"<"} BACK TO TEAM 23
              </a>
            </div> */}

            <h1 style={{ color: "#1a202a" }} className="vote-header">
              {selectedArtist.title}
            </h1>
            <p
              id="vote-desc"
              style={{ fontFamily: "monument-bold", color: "#1a202a" }}
            >
              {" "}
              FOR 2023 MVP
            </p>
          </div>

          <div style={{ marginTop: 20 }}>
            <ShareLink
              style={{ backgroundColor: "#1a202a", border: "none" }}
              className="animate__animated animate__pulse"
              target="_blank"
              href={`https://twitter.com/intent/tweet?url=${selectedArtist.sharingUrl}&text=I just voted ${selectedArtist.title} as MVP for %23NSYearbook`}
            >
              Share your vote
            </ShareLink>
          </div>
          {/* <p
            style={{
              marginTop: "-15px",
              fontFamily: "monument-bold",
              fontSize: 18,
              color: "#1a202a",
            }}
          >
            YOUR VOTE HAS BEEN SUBMITTED{" "}
          </p> */}

          <div style={{ maxWidth: 350, margin: "20px auto" }}>
            <img
              style={{ width: "100%", display: "block" }}
              src={selectedArtist.image}
            ></img>
          </div>

          {/* {votedStatus && <p style={{ fontSize: "1.2rem" }}>{votedStatus}</p>} */}

          <br />
          {/* <p style={{ fontSize: 16 }}>
            Support {selectedArtist.title} By Sharing on Socials
          </p> */}
          <br />
        </div>
      </div>
    );

  if (loading)
    return (
      <div
        style={{
          padding: 40,
          minHeight: "400px",
          maxWidth: 1080,
          margin: "auto",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        className="animate_animated animate__zoomOut"
      >
        {/* <img width="150px" src={tie} /> */}
        <img
          width="60px"
          src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif"
        />
        <h4 style={{ fontSize: "1.2rem", fontFamily: "monument-regular" }}>
          Submitting vote to No Signal HQ
        </h4>
      </div>
    );

  return (
    <div>
      <div
        style={{
          padding: 40,
          minHeight: "100vh",
          maxWidth: 1080,
          margin: "auto",
          textAlign: "center",
        }}
      >
        <div style={{ width: "fit-content", margin: "auto" }}>
          {/* <div style={{ textAlign: "left" }}>
            <a style={{ fontSize: 18 }} href="/2023">
              {"<"} BACK TO TEAM 23
            </a>
          </div> */}

          <h1 className="vote-header">Choose your 2023 MVP</h1>
        </div>
        {/* <p style={{ marginTop: "-15px", fontFamily: "monument-bold" }}>
          {selectedArtist.title}
        </p> */}
        <div
          style={{
            display: "flex",
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <Select
            style={{
              color: "white !important",
              backgroundColor: "rgb(92 92 92) !important",
            }}
            onChange={(e) => {
              e.preventDefault();
              console.log();
              setSelectedArtist(
                artists.find((a) => a.title === e.target.value)
              );
            }}
            value={selectedArtist.title}
          >
            {artists.map((artist) => (
              <option value={artist.title}>{artist.title}</option>
            ))}
          </Select>
          <VoteButton onClick={vote}>VOTE</VoteButton>
        </div>

        <div
          key={selectedArtist.id}
          style={{ maxWidth: 350, margin: "20px auto" }}
          className="animate__animated animate__flipInY"
        >
          <img
            style={{ width: "100%", display: "block" }}
            src={selectedArtist.image}
          ></img>
        </div>

        {error && (
          <div style={{ color: "red", fontSize: 14 }}>
            Please enter your name and valid email
          </div>
        )}

        {/* {requireEmail && (
          <div>
            <div>
              <input
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                style={inputStyle}
              ></input>
            </div>

            <div style={{ marginBottom: 5 }}>
              <input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                style={inputStyle}
                type="email"
              ></input>
            </div>
          </div>
        )} */}

        <br />
        <br />
        <div
          style={{
            paddingTop: 45,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <div>
            <Link to="/">
              <img width="60px" src={nosignal} />
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Vote;
