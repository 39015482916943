import React from "react";
import Enter from "./Enter";
import ArtistsGrid from "./ArtistsGrid";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Artists2021, Artists2022, Artists2023 } from "./data";
import TwentyTwo from "./TwentyTwo";
import { useState } from "react";
import Vote from "./Vote";
import Profile from "./components/Profile";
import Navbar from "./components/NavBar";
import yearbook23 from "./images/yearbook23.png";

export default function App() {
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);

  return (
    <Router>
      <Switch>
        <Route exact path="/2023">
          <div
            style={{
              padding: 20,
              minHeight: "100vh",
              maxWidth: 1080,
              margin: "auto",
              textAlign: "center",
            }}
          >
            <Navbar />
            <h1 id="header" style={{ marginBottom: 18 }}>
              The Winning Team
            </h1>

            <ArtistsGrid preview="location" artists={Artists2023} />
          </div>
        </Route>
        <Route exact path="/2022">
          <div
            style={{
              padding: 20,
              minHeight: "100vh",
              maxWidth: 1080,
              margin: "auto",
              textAlign: "center",
            }}
          >
            {" "}
            <Navbar />
            <h1>The Class of 2022</h1>
            <ArtistsGrid artists={Artists2022} />
          </div>
        </Route>
        <Route exact path="/2021">
          <div
            style={{
              padding: 20,
              minHeight: "100vh",
              maxWidth: 1080,
              margin: "auto",
              textAlign: "center",
            }}
          >
            <Navbar />

            <h1>The Class of 2021</h1>
            <ArtistsGrid artists={Artists2021} />
          </div>
        </Route>

        <Route path="/vote">
          <>
            <br />
            <Navbar />
            <Vote email={email} name={name} />
          </>
        </Route>

        {Artists2022.map((artist) => (
          <Route
            path={`/profile/${artist.id}`}
            render={(props) => (
              <div>
                <br />

                <Navbar />
                <Profile id={artist.id} artists={Artists2022} />
              </div>
            )}
          />
        ))}

        {Artists2023.map((artist) => (
          <Route
            path={`/profile/${artist.id}`}
            render={(props) => (
              <div>
                <br />
                <Navbar />
                <Profile id={artist.id} artists={Artists2023} />
              </div>
            )}
          />
        ))}

        <Route path="/">
          <div
            style={{
              padding: 20,
              minHeight: "100vh",
              maxWidth: 1080,
              margin: "auto",
              textAlign: "center",
            }}
          >
            <Navbar />
            <h1 id="header" style={{ marginBottom: 18 }}>
              The Winning Team
            </h1>
            <ArtistsGrid preview="location" artists={Artists2023} />
          </div>
        </Route>
      </Switch>
    </Router>
  );
}
