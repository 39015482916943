import React from "react";
import styled from "styled-components";
import Card from "./Card";

const GridContainer = styled.ul`
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 30px 0px;
`;

const CardGrid = ({ cardData, preview }) => (
  <div
    style={{
      maxWidth: 1200,
      display: "flex",
      alignContent: "center",
      justifyItems: "center",
      margin: "auto",
    }}
  >
    <GridContainer>
      {cardData.map((data) => (
        <Card
          title={data.title}
          image={data.image}
          description={
            preview === "location" ? data.location : data.description
          }
          link={data.link}
          id={data.id}
        />
      ))}
    </GridContainer>
  </div>
);

export default CardGrid;
