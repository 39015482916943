import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CardItem = styled.li`
  display: -webkit-box;
  display: flex;
  padding: 1rem;
  width: 50%;
  @media (min-width: 40rem) {
    width: 33.333333%;
  }
  @media (min-width: 56rem) {
    width: 25%;
  }
`;

const Card = styled.div`
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  overflow: hidden;
`;

const CardContent = styled.div`
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 1.5rem 0px;
  text-align: left;
`;

const CardImage = styled.img`
  // border: 3px solid black;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
  @media (min-width: 40rem) {
    &::before {
      padding-top: 66.6%;
    }
  }
`;

const CardTitle = styled.h2`
  font-size: 1.8rem;
  text-transform: uppercase;
  margin: 0px;
  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
  // margin-bottom: 12px;
  margin-bottom: -10px;
`;

const CardDesc = styled.p`
  margin: 0px;
  font-size: 12px;
  word-spacing: 2px;
  line-height: 1rem;
`;

const CardButtons = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 8px;
  flex-wrap: wrap;
`;

const CardButton = styled.button`
  border: 2px solid black;
  font-family: "machine";
  font-size: 1.2rem;
  background-color: white;
  border-radius: 26px;
  padding: 6px 20px;
  text-transform: uppercase;
  color: black !important;
  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 6px 10px;
  }
`;

export default ({ title, description, image, id }) => (
  <CardItem>
    <Link to={`/profile/${id}`}>
      <Card className="artist-image">
        <CardImage top width="100%" src={image} alt="Card image cap" />
        <CardContent>
          <CardTitle>{title}</CardTitle>
          {description && <CardDesc>{description}</CardDesc>}
          {/* <CardButtons>
          <CardButton>Follow</CardButton>
          <CardButton>Listen</CardButton>
        </CardButtons> */}
        </CardContent>
      </Card>
    </Link>
  </CardItem>
);
