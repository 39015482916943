import React, { useCallback, useEffect, useRef } from "react";

import JSConfetti from "js-confetti";

function useConfetti() {
  const jsConfettiRef = useRef();

  useEffect(() => {
    jsConfettiRef.current = new JSConfetti();
  }, []);

  const onButtonClick = useCallback(() => {
    if (jsConfettiRef.current) {
      jsConfettiRef.current
        .addConfetti({
          confettiRadius: 10,
          confettiNumber: 75,
          emojis: Math.random() > 0.5 ? ["🔥"] : ["💯"],
        })
        .then(() => console.log("Manual batch completed"));
    }
  }, [jsConfettiRef]);

  return [onButtonClick];
}

export default useConfetti;
