import React from "react";
import logo from "./images/yearbook-22.svg";
import styled from "styled-components";
import "animate.css";
import Navbar from "./components/NavBar";
import { useHistory } from "react-router-dom";

const EnterImage = styled.img`
  margin: auto;
  display: block;
  width: 500px;
  @media (max-width: 768px) {
    width: 330px;
  }
`;

const Enter = ({ name, setName, email, setEmail }) => {
  const router = useHistory();
  return (
    <>
      <Navbar />
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "column",
          overflow: "hidden",
          background: "#1d1d1d",
        }}
        className="animate__animated animate__fadeIn animate__slow"
      >
        <div style={{ display: "flex", margin: "0px auto", maxWidth: 950 }}>
          <div className="image-container">
            <EnterImage id="enter-image" alt="ns-yearbook-22" src={logo} />
          </div>
          <div
            style={{
              textAlign: "",
              display: "flex",
              flexDirection: "column",
              gap: 15,
              padding: 30,
              fontSize: "1.4rem",
            }}
          >
            <h1 style={{ color: "white" }}>Introducing the winning team</h1>
            <button
              style={{
                backgroundColor: "#d9d9d9",
                color: "#1d1d1d",
                margin: "10px 0px",
                width: 220,
                borderRadius: 10,
                padding: "10px 35px",
                fontFamily: "nichrome-black",
                fontSize: 28,
                border: "none",
              }}
              onClick={(e) => {
                e.preventDefault();
                router.push("/2023");
              }}
            >
              VIEW LINEUP
            </button>
          </div>
        </div>

        {/* <div
        style={{
          display: "flex",
          position: "fixed",
          bottom: 20,
          height: 60,
          margin: "0px auto",
          gap: 25,
          left: "50%",
          transform: "translateX(-50%)",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="animate__animated animate__fadeIn animate__slower"
      >
        <img alt="no-signal-logo" width="50px" src={noSignal} />
        <img alt="diabolical-logo" width="90px" src={diabolical} />
      </div> */}
      </div>
    </>
  );
};

export default Enter;
