import React from "react";
import CardGrid from "./components/CardGrid";

const Home = ({ artists, preview }) => {
  const randArtists = artists && artists.sort(() => 0.5 - Math.random());

  return (
    <div style={{ minHeight: "100vh" }}>
      <CardGrid preview={preview} cardData={randArtists} />
    </div>
  );
};

export default Home;
