import a1J1 from "./images/2021_a1j1.jpg";
import amaria from "./images/2021_amaria-bb.jpg";
import anaiis from "./images/2021_anaiis.jpg";
import arz from "./images/2021_arz.jpg";
import berwyn from "./images/2021_berwyn.jpg";
import enny from "./images/2021_enny.jpg";
import gabzy from "./images/2021_gabzy.jpg";
import kwengface from "./images/2021_kwengface.jpg";
import lostGirl from "./images/2021_lostgirl.jpg";
import missLaFamilia from "./images/2021_miss-la-familia.jpg";
import odeal from "./images/2021_odeal.jpg";
import shaShimone from "./images/2021_sha-shimone.jpg";
import weWantWraiths from "./images/2021_wewantwraiths.jpg";
import ysSainte from "./images/2021_ys-sainte.jpg";

import ayra from "./images/2022/ayra-starr.jpg";
import blacko from "./images/2022/blacko.jpg";
import catBurns from "./images/2022/cat-burns.jpg";
import cristale from "./images/2022/cristale.jpg";
import flo from "./images/2022/flo.jpg";
import glorilla from "./images/2022/glorilla.jpg";
import knucks from "./images/2022/knucks.jpg";
import m24 from "./images/2022/m24.jpg";
import nemzz from "./images/2022/nemzz.jpg";
import nia from "./images/2022/nia.jpg";
import skillibeng from "./images/2022/skilli.jpg";
import dreya from "./images/2022/dreya.jpg";

//secondary images
import ayra2 from "./images/2022/ayra-starr-2.jpg";
import blacko2 from "./images/2022/blacko-2.jpg";
import catBurns2 from "./images/2022/cat-burns-2.jpg";
import cristale2 from "./images/2022/cristale-2.jpg";
import flo2 from "./images/2022/flo-2.jpg";
import glorilla2 from "./images/2022/glorilla-2.jpg";
import knucks2 from "./images/2022/knucks-2.jpg";
import m242 from "./images/2022/m24-2.jpg";
import nemzz2 from "./images/2022/nemzz-2.jpg";
import nia2 from "./images/2022/nia-2.jpg";
import skillibeng2 from "./images/2022/skilli-2.jpg";
import dreya2 from "./images/2022/dreya-2.jpg";

//2023
import cavemen from "./images/2023/cavemen.jpg";
import cocojones from "./images/2023/coco-jones.jpg";
import destinconrad from "./images/2023/destin-conrad.jpg";
import jayo from "./images/2023/jayo.jpg";
import len from "./images/2023/len.jpg";
import libianca from "./images/2023/libianca.jpg";
import mike from "./images/2023/mike.jpg";
import odumodu from "./images/2023/odumodu.jpg";
import strandz from "./images/2023/strandz.jpg";
import tyla from "./images/2023/tyla.jpg";
import yazminlacey from "./images/2023/yazmin-lacey.jpg";
import waffles from "./images/2023/waffles.jpg";
import teejay from "./images/2023/teejay.jpg";
import seyivibez from "./images/2023/seyivibez.jpg";

// profiles import
import Profile from "./components/Profile";

export const Artists2021 = [
  {
    title: "A1 x J1",
    description: "",
    image: a1J1,
  },
  {
    title: "Amaria BB",
    description: "",
    image: amaria,
  },
  {
    title: "ANAIIS",
    image: anaiis,
    description: "",
  },
  {
    title: "ARZ",
    image: arz,
    description: "",
  },
  {
    title: "berwyn",
    image: berwyn,
    description: "",
  },
  {
    title: "enny",
    image: enny,
    description: "",
  },
  {
    title: "gabzy",
    description: "",
    image: gabzy,
  },
  {
    title: "KWENGFACE",
    description: "",
    image: kwengface,
  },
  {
    title: "Lost Girl",
    description: "",
    image: lostGirl,
  },
  {
    id: "MISS LAFAMILIA",
    title: "MISS LAFAMILIA",
    description: "",
    image: missLaFamilia,
  },
  {
    title: "Odeal",
    description: "",
    image: odeal,
  },
  {
    title: "SHASIMONE",
    image: shaShimone,
    description: "",
  },

  {
    title: "WEWANTWRAITHS",
    description: "",
    image: weWantWraiths,
  },
  {
    title: "Ys Sainte",
    description: "",
    image: ysSainte,
  },
];

export const Artists2022 = [
  {
    id: "nia",
    title: "Nia Archives",
    description:
      "DJ, producer, singer, songwriter. Nia Archives is a true trailblazer, reigniting and carrying the torch for jungle. Name another artist doing it like her… we’ll wait.",
    image: nia,
    image2: nia2,
    spotify: "https://open.spotify.com/artist/7BMR0fwtEvzGtK4rNGdoiQ",
    instagram: "https://www.instagram.com/archives.nia/",
    spotifyId: "7BMR0fwtEvzGtK4rNGdoiQ",
    sharingUrl: "https://www.nsyearbook.com/nia.html",
  },
  {
    id: "ayra",
    title: "Ayra Starr",
    description:
      "Ethereal Nigerian rising star, shaking up the game at 20. Watch this space cause truly E dey rush for the Celestial Being.",
    image: ayra,
    image2: ayra2,
    spotify: "https://open.spotify.com/artist/3ZpEKRjHaHANcpk10u6Ntq",
    instagram: "https://www.instagram.com/ayrastarr/",
    spotifyId: "3ZpEKRjHaHANcpk10u6Ntq",
    sharingUrl: "https://www.nsyearbook.com/ayra.html",
  },
  {
    id: "nemzzz",
    title: "Nemzzz",
    description:
      "Mr D.N.D himself. His signature staccato flow and playful lyrics put the Manny native in a lane of his own.",
    image: nemzz,
    image2: nemzz2,
    spotify: "https://open.spotify.com/artist/3DHtfeD4PsmR9YGhCP4VF7",
    instagram: "https://www.instagram.com/nemzzz_/",
    spotifyId: "3DHtfeD4PsmR9YGhCP4VF7",
    sharingUrl: "https://www.nsyearbook.com/nemzz.html",
  },
  {
    id: "flo",
    title: "Flo",
    description:
      "It’s FLO’s world and we’re just living in it. The IT girls have had a year to remember, it’s about time they take The Lead on a global stage.",
    image: flo,
    image2: flo2,
    spotify: "https://open.spotify.com/artist/0s4kXsjYeH0S1xRyVGN4NO",
    instagram: "https://www.instagram.com/flolikethis/",
    spotifyId: "0s4kXsjYeH0S1xRyVGN4NO",
    sharingUrl: "https://www.nsyearbook.com/flo.html",
  },
  {
    id: "dreya",
    title: "Dréya Mac",
    description:
      "Already a triple threat, Dréya Mac is coming for the performing crown too. If you weren’t singing about Madison or Maddy this year, where were you?",
    image: dreya,
    image2: dreya2,
    spotify: "https://open.spotify.com/artist/03AVZyE7WnmLY4rTL1s0RP",
    instagram: "https://www.instagram.com/dreyamac/",
    spotifyId: "03AVZyE7WnmLY4rTL1s0RP",
    sharingUrl: "https://www.nsyearbook.com/dreya.html",
  },
  {
    id: "cristale",
    title: "Cristale",
    description:
      "Swiss army knife Cristale can hit you with banter, introspection or vibes on a track; she’s even a baller too. What can’t she do?",
    image: cristale,
    image2: cristale2,
    spotify: "https://open.spotify.com/artist/6U1hXHFTV27cbQVXZvnX27",
    instagram: "https://www.instagram.com/cristaleyy/",
    spotifyId: "6U1hXHFTV27cbQVXZvnX27",
    sharingUrl: "https://www.nsyearbook.com/cristale.html",
  },
  {
    id: "glorilla",
    title: "Glorilla",
    description:
      "GloRilla is a testament to the benefits of being F.N.F as she’s taken the world by storm this year. Forget tomorrow, Big Glo is ready to take over today!",
    image: glorilla,
    image2: glorilla2,
    spotify: "https://open.spotify.com/artist/2qoQgPAilErOKCwE2Y8wOG",
    instagram: "https://www.instagram.com/glorillapimp/",
    spotifyId: "2qoQgPAilErOKCwE2Y8wOG",
    sharingUrl: "https://www.nsyearbook.com/glorilla.html",
  },
  {
    id: "m24",
    title: "M24",
    description:
      "True to his name, M24 has been a lethal weapon this year. With an unmatched voice and delivery, he hasn’t missed once.",
    image: m24,
    image2: m242,
    spotify: "https://open.spotify.com/artist/601bmA9VRZnMVclsxG7W6T",
    instagram: "https://www.instagram.com/m24official1/",
    spotifyId: "601bmA9VRZnMVclsxG7W6T",
    sharingUrl: "https://www.nsyearbook.com/m24.html",
  },
  {
    id: "skillibeng",
    title: "Skillibeng",
    description:
      "Fusing trap with dancehall, Skillibeng has carved a unique lane for himself. Who hasn’t imitated his iconic adlibs?",
    image: skillibeng,
    image2: skillibeng2,
    spotify: "https://open.spotify.com/artist/5FkUhnHQ0KC63549LHHtst",
    instagram: "https://www.instagram.com/skillibeng/",
    spotifyId: "5FkUhnHQ0KC63549LHHtst",
    sharingUrl: "https://www.nsyearbook.com/skillibeng.html",
  },
  {
    id: "black-sherif",
    title: "Black Sherif",
    description:
      "The Black Star whose light never dims. Kwaku the Traveller should put his hands in the air because this year, he has never missed a beat!",
    image: blacko,
    image2: blacko2,
    spotify: "https://open.spotify.com/artist/2LiqbH7OhqP0yuaG8VL1wJ",
    instagram: "https://www.instagram.com/blacksherif_/",
    spotifyId: "2LiqbH7OhqP0yuaG8VL1wJ",
    sharingUrl: "https://www.nsyearbook.com/blacko.html",
  },
  {
    id: "cat",
    title: "Cat Burns",
    description:
      "Cat Burns has had the year of dreams. She might be emotionally unavailable but she has successfully got us all in our feels this year.",
    image: catBurns,
    image2: catBurns2,
    spotify: "https://open.spotify.com/artist/6WFDpw4u23uSpon4BHvFRn",
    instagram: "https://www.instagram.com/catburns/",
    spotifyId: "6WFDpw4u23uSpon4BHvFRn",
    sharingUrl: "https://www.nsyearbook.com/cat-burns.html",
  },
  {
    id: "knucks",
    title: "Knucks",
    description:
      "Nobody can paint a clearer picture with words than South Kilburn storyteller, Knucks. He took us Home with Alpha Place and made it clear why he’s rated so highly!",
    image: knucks,
    image2: knucks2,
    spotify: "https://open.spotify.com/artist/6W4vm8P3JFQboO4cvHeqaa",
    instagram: "https://www.instagram.com/knucks_music/",
    spotifyId: "6W4vm8P3JFQboO4cvHeqaa",
    sharingUrl: "https://www.nsyearbook.com/knucks.html",
  },
];

export const Artists2023 = [
  {
    id: "tyla",
    title: "Tyla",
    description:
      "In 2019, South African songstress Tyla released her first track ‘Getting Late’ which made waves from Johannesburg to Cape Town. Since then she has been going from strength to strength for her vibrant and innovative approach to pop music. The singer-dancer often blends amapiano and R&B to shape her sound and is compared to icons like Aaliyah and Lady Gaga for her star-like qualities. Earlier this year, she dropped her biggest hit to date, ‘Water’ which has led to huge global, commercial success.",
    image: tyla,
    image2: "",
    spotify: "https://open.spotify.com/artist/3SozjO3Lat463tQICI9LcE",
    instagram: "https://www.instagram.com/tyla/",
    spotifyId: "3SozjO3Lat463tQICI9LcE",
    sharingUrl: "https://www.nsyearbook.com/2023/tyla.html",
    location: "SOUTH AFRICA",
  },
  {
    id: "teejay",
    title: "Teejay",
    description:
      "Teejay is a prominent Jamaican artist carving a lane for himself within dancehall, he is known for songs such as ‘Up Top Boss,’ ‘Brawling’ and more recently his viral 2023 hit ‘Drift.’ His consistent bangers, catchy flows and popular hooks has gained him a growing fanbase. He is looked after by Romeich Entertainment which is home to dancehall stars Shenseea and Ding Dong.",
    image: teejay,
    image2: "",
    spotify: "https://open.spotify.com/artist/30hElzuHCZ1qzCl364SHma",
    instagram: "https://www.instagram.com/teejay.uptopboss/",
    spotifyId: "30hElzuHCZ1qzCl364SHma",
    sharingUrl: "https://www.nsyearbook.com/2023/teejay.html",
    location: "JAMAICA",
  },
  {
    id: "the-cavemen",
    title: "The Cavemen",
    description:
      "Brothers Kingsley and Benjamin James are the Nigerian musical duo waving the flag for highlife - a jazzy, soulful, unique genre based in West Africa. The duo use their experiences of living in music hotspot Lagos to push the sound forward and celebrate their heritage. Their last two albums “Love and Highlife” and “Roots” explore how they blend traditional Nigerian music with a contemporary twist.",
    image: cavemen,
    image2: "",
    spotify: "https://open.spotify.com/artist/1cnBVQulaNSvbind6A0dVD",
    instagram: "https://www.instagram.com/the.cavemen/",
    spotifyId: "1cnBVQulaNSvbind6A0dVD",
    sharingUrl: "https://www.nsyearbook.com/2023/cavemen.html",
    location: "NIGERIA",
  },
  {
    id: "coco-jones",
    title: "Coco Jones",
    description:
      'Coco Jones is an American singer, songwriter, and actress. She gained recognition for her versatile talents in the entertainment industry. She later starred in the Disney Channel movie "Let It Shine" in 2012, where she showcased her singing and acting abilities. Apart from her acting career, Coco Jones has pursued music, releasing singles and EPs that showcase her powerful vocals and diverse musical influences. This year she has released a remix to her well-known track “ICU” with Justin Timberlake and What I Didn’t Tell You deluxe album featuring R&B legend Babyface.',
    image: cocojones,
    image2: "",
    spotify: "https://open.spotify.com/artist/4DHLoiIqFYYFjH09WduvFd",
    instagram: "https://www.instagram.com/cocojones/",
    spotifyId: "4DHLoiIqFYYFjH09WduvFd",
    sharingUrl: "https://www.nsyearbook.com/2023/cocojones.html",
    location: "AMERICA",
  },
  {
    id: "strandz",
    title: "Strandz",
    description:
      "London-based rapper Strandz is bringing hip hop and love to the forefront of British music with his swagger and smooth raps. The young rapper had commercial success with tracks like “J’adore” and “Me Against the World” earlier this year, and the remix featured rap and drill frontrunner Digga D. In the summer, Strandz opened up for hip hop legends Nas and Wu-Tang on their London show at the O2 as well. He has recently teased an exciting collaboration with Lancey Foux who has been making waves in rap globally.",
    image: strandz,
    image2: "",
    spotify: "https://open.spotify.com/artist/33X2jfLSVyDevoksJjRZoS",
    instagram: "https://www.instagram.com/officialstrandz/",
    spotifyId: "33X2jfLSVyDevoksJjRZoS",
    sharingUrl: "https://www.nsyearbook.com/2023/strandz.html",
    location: "BRITAIN",
  },
  {
    id: "len",
    title: "Len",
    description:
      "South London artist Len is bound by no genre. He has infused trap, afrobeats and alté into his sound masterfully. This year he has dropped a string of bangers, including “Rackistan”, “Bebe” featuring Odunsi (The Engine) and “Addiction” featuring Brazy Bih leading up to his highly anticipated mixtape “Lehgoland.” This month, Len has completed his own UK wide tour and has opened up for trap music heavyweight Lancey Foux on his tour alongside musical peer and collaborator Fimiguerro.",
    image: len,
    image2: "",
    spotify: "https://open.spotify.com/artist/2siXaXrok89Fp1PZI7sn0s",
    instagram: "https://www.instagram.com/p/Cwm3wggNVD5/?img_index=1",
    spotifyId: "2siXaXrok89Fp1PZI7sn0s",
    sharingUrl: "https://www.nsyearbook.com/2023/len.html",
    location: "BRITAIN",
  },
  {
    id: "jayo",
    title: "JayO",
    description:
      "Emerging from Tottenham, an area known for its rich musical heritage and artists like Skepta, Adele and Headie One, JayO is also making a mark on the musical landscape. Contributing to afroswing and R&B in his own way JayO has had huge global success with tracks like “22” and “XO” and it doesn’t seem like he is stopping anytime soon, especially since dropping his latest release “Often.”",
    image: jayo,
    image2: "",
    spotify: "https://open.spotify.com/artist/1IMENE2OCzsrXuu62aW1mD",
    instagram: "https://www.instagram.com/jayoagain/?hl=en",
    spotifyId: "1IMENE2OCzsrXuu62aW1mD",
    sharingUrl: "https://www.nsyearbook.com/2023/jayo.html",
    location: "BRITAIN",
  },
  {
    id: "libianca",
    title: "Libianca",
    description:
      "Known for her transparency and soulful harmonies, Minnesota-based artist Libianca blends afrobeats with R&B sonics to convey her unique sound. This year, the singer dropped her chart-topping smash and breakout hit “People” a track that explores difficulty and hardship in an empathetic and honest way. She has also been making music with award-winning producer Jae 5 and featured on afroswing icons NSG’s recent project AREA BOYZ.",
    image: libianca,
    image2: "",
    spotify: "https://open.spotify.com/artist/7kjSuFGKhLm8b5qXoMhRkJ",
    instagram: "https://www.instagram.com/iamlibianca/",
    spotifyId: "7kjSuFGKhLm8b5qXoMhRkJ",
    sharingUrl: "https://www.nsyearbook.com/2023/libianca.html",
    location: "AMERICA",
  },
  {
    id: "mike",
    title: "MIKE",
    description:
      "New York-based rapper MIKE has been steadily carving his lane and building the foundations of his credible career over the years. In 2023, he dropped the highly acclaimed album “Beware of the Monkey” and collaborated with Wiki and The Alchemist for their “Faith Is A Rock” album. MIKE’s role in underground American rap culture has been integral to the growth of hip hop globally and with the release of “Burning Desire” it is clear that he is not slowing down any time soon.",
    image: mike,
    image2: "",
    spotify: "https://open.spotify.com/artist/1wlzPS1hSNrkriIIwLFTmU",
    instagram: "https://www.instagram.com/mikelikesrap",
    spotifyId: "1wlzPS1hSNrkriIIwLFTmU",
    sharingUrl: "https://www.nsyearbook.com/2023/mike.html",
    location: "AMERICA",
  },
  {
    id: "destin-conrad",
    title: "Destin Conrad",
    description:
      "Floridian R&B singer-songwriter Destin Conrad had only released a few songs leading up to his successful 2021 project, COLORWAY, which contains the viral hit ‘IN THE AIR’ that blew up on social media. This year Destin has been on his European tour and in October he dropped his most recent album “SUBMISSIVE” which features the likes of Masego and Jordan Ward.",
    image: destinconrad,
    image2: "",
    spotify: "https://open.spotify.com/artist/4jwROPSUkTkohLCRiyjiZZ",
    instagram: "https://www.instagram.com/destinconrad/",
    spotifyId: "4jwROPSUkTkohLCRiyjiZZ",
    sharingUrl: "https://www.nsyearbook.com/2023/destin-conrad.html",
    location: "AMERICA",
  },
  {
    id: "yazmin-lacey",
    title: "Yazmin Lacey",
    description:
      "Yazmin Lacey is a British singer-songwriter known for her soulful and jazz-infused sound. Hailing from East London, Lacey has garnered attention for her captivating vocals and introspective lyrics. Her music often explores themes of love and self-discovery. Yazmin Lacey dropped her debut project Voice Notes this year. The east London artist is also about to embark on her European tour after having a successful festival season.",
    image: yazminlacey,
    image2: "",
    spotify: "https://open.spotify.com/artist/2datC2OML2YxykP6vnDRmg",
    instagram: "https://www.instagram.com/yazminlacey/",
    spotifyId: "2datC2OML2YxykP6vnDRmg",
    sharingUrl: "https://www.nsyearbook.com/2023/yazmin-lacey.html",
    location: "BRITAIN",
  },

  {
    id: "seyi-vibez",
    title: "Seyi Vibez",
    description:
      "Seyi Vibez is a Nigerian singer and songwriter known for his presence in the Afrobeat and Afropop music scene. He has been making music since 2020 and has gained popularity over the past year for his unique style and sound. Seyi’s music often explores themes of love, life, and personal experiences. This year he dropped Vibe Till Thy Kingdom Come and Thy Kingdom Come which contains features from the likes of Phyno and Young Jonn. His consistency has allowed him to build a cult-like following in Nigeria and beyond.",
    image: seyivibez,
    image2: "",
    spotify: "https://open.spotify.com/artist/4zmZ8lVLzGc84S4v2B1rLx",
    instagram: "https://www.instagram.com/seyi_vibez/?hl=en",
    spotifyId: "4zmZ8lVLzGc84S4v2B1rLx",
    sharingUrl: "https://www.nsyearbook.com/2023/seyivibez.html",
    location: "NIGERIA",
  },
  {
    id: "odumodu-blvck",
    title: "Odumodu Blvck",
    description:
      "After dropping his smash hit “Declan Rice” Nigerian rapper Odumodu Blvck has worked with Arsenal and was co-signed by grime legend and fashion icon Skepta for his fresh approach to rap. Earlier this year, he was also featured on the American hip-hop and rap platform On the Radar known for championing artists like AJ Tracey, Central Cee and Dthang. He also collaborated with Unknown T on “WELCOME 2 MY STRIP”; last month, Odumodu dropped his highly anticipated project EZIOKWU after a very exciting year.",
    image: odumodu,
    image2: "",
    spotify: "https://open.spotify.com/artist/3LOm0AZjpwVQebvkyanjDy",
    instagram: "https://www.instagram.com/odumodublvck/?hl=en",
    spotifyId: "3LOm0AZjpwVQebvkyanjDy",
    sharingUrl: "https://www.nsyearbook.com/2023/Odumodu.html",
    location: "NIGERIA",
  },
  {
    id: "uncle-waffles",
    title: "Uncle Waffles",
    description:
      "South African artist Uncle Waffles has gained popularity for her production skills, viral dancing and collaboration with other notable Amapiano artists such as Tony Duardo and Pcee. Waffles’ career in music has been characterised by creating infectious music. Her album ASYLUM and catchy dance moves have contributed to the global appeal of Amapiano music.  ",
    image: waffles,
    image2: "",
    spotify: "https://open.spotify.com/artist/68McnNC9twEtiynOAJRRgZ",
    instagram: "https://www.instagram.com/unclewaffles_/",
    spotifyId: "68McnNC9twEtiynOAJRRgZ",
    sharingUrl: "https://www.nsyearbook.com/2023/waffles.html",
    location: "ESWATINI",
  },
];
