import React from "react";
// import "../index.css";
import yearbook23 from "../images/yearbook23.png";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";

const CardButtons = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const CardButton = styled.button`
  border: none;
  font-size: 1.2rem;
  background-color: white;
  // border-radius: 26px;
  padding: 12px 20px 6px;
  text-transform: uppercase;
  color: black !important;
  margin: 12px auto 8px;
`;

const VoteButton = styled.button`
  border: 2px solid black;
  font-size: 1.2rem;
  background-color: black;
  color: white;
  // border-radius: 26px;
  padding: 12px 20px 6px;
  text-transform: uppercase;
  margin: 12px auto 8px;
`;

export default ({ id, artists }) => {
  const defaultArtist = artists.find((artist) => artist.id === id);
  const [artist, setArtist] = React.useState(defaultArtist);
  const [nextArtist, setNextArtist] = React.useState();

  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const currentId = artist.id;
    const artistIndex = artists.findIndex((artist) => artist.id === currentId);

    const _nextArtist =
      artistIndex + 1 === artists.length
        ? artists[0]
        : artists[artistIndex + 1];

    window.history.replaceState(null, artist.title, "/profile/" + artist.id);

    setNextArtist(_nextArtist);
  }, [artist]);

  useEffect(() => {
    setArtist(defaultArtist);
  }, [defaultArtist]);

  return (
    <div>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://i.imgur.com/ZOWubiQ.png" />
      </Helmet>
      <div
        style={{
          padding: 20,
          minHeight: "100vh",
          maxWidth: 1080,
          margin: "auto",
        }}
      >
        <div className="container">
          <div style={{ padding: "0px 12px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <a style={{ fontSize: 18 }} href="/2023">
                {"<"} BACK TO TEAM 23
              </a>
              <a style={{ fontSize: 18 }} onClick={() => setArtist(nextArtist)}>
                NEXT ARTIST {">"}
              </a>
            </div>

            <h1 id="artist-title">{artist.title}</h1>
            <p id="artist-description">{artist.description}</p>
            <div style={{ marginTop: 20 }}>
              <CardButtons>
                <a onClick={() => history.push("/vote")} href="#">
                  <CardButton>Vote 2023 MVP</CardButton>
                </a>
                {/* <div>
                  <a
                    target="_blank"
                    href={artist.instagram}
                    style={{ marginRight: 8 }}
                  >
                    <CardButton>Follow</CardButton>
                  </a>
                  <a target="_blank" href={artist.spotify}>
                    <CardButton>Listen</CardButton>
                  </a>
                </div> */}
              </CardButtons>
            </div>

            <div style={{ maxWidth: 425 }}>
              <iframe
                style={{ borderRadius: 12, marginTop: 20 }}
                src={`https://open.spotify.com/embed/artist/${artist.spotifyId}?utm_source=generator&theme=1`}
                width="100%"
                height="80"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div
            style={{
              maxWidth: "100%",
              display: "flex",
              flexWrap: "wrap",
              padding: "0px 12px",
            }}
          >
            <img
              className="profile-image"
              style={{
                margin: "30px auto",
                display: "block",

                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              src={artist.image}
            />
            <img
              className="profile-image"
              style={{
                margin: "30px auto",
                display: "block",

                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              src={artist.image2}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
